body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-cell #three-dots {
  margin-left: '0 1.5 rem'

}

.ant-table-thead ::before {
  width: 0
}

.myForm {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.myForm h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #3A3F45;
}

.myForm h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #3A3F45;

}

.myForm p {

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3A3F45;
}

.ant-upload .ant-upload-select
 {
  width: 1rem;
  height: 1rem

}

.indexing {
  background-color: #E5F4FF;
   color: #0065AD;
   padding: 0.75em 1.5em
}

.active {
  background-color: #E9FCF2;
   color: #0F6737;
   padding: 0.75em 1.5em

  
}

.active img{
  display: none
   
  
}

.indexing img {
  animation: spin 2s linear infinite;
  margin-bottom: -3px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}